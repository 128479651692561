import { AxiosPromise } from "axios"
import { api } from "../../../api"
import { IGetBuses } from "./types/IGetBuses"

export class BusesService {
    public getBusesList(contractorId: number): AxiosPromise<IGetBuses[]> {
        let url = `/Buses/List?contractorId=${contractorId}`

        return api.get(url)
    }
}
