import { Mode } from "../../global"
import { services } from "../../services"
import { AppDispatch } from "../types"
import { SET_CONTRACTORS, SET_MODE } from "./constants"

export const changeAppMode = (mode: Mode) => {
	return async (dispatch: AppDispatch) => {
		dispatch({
			type: SET_MODE,
			payload: mode,
		})
	}
}

export const getContractors = () => {
	return async (dispatch: AppDispatch) => {
		try {
			const response = await services.contractors.getContractors()
			dispatch({
				type: SET_CONTRACTORS,
				payload: response.data,
			})
		}
		catch (e: any) {
			console.log(e.response)
		}
	}
}

