import { AxiosPromise } from "axios";
import { api } from "../../../api";
import { IProfile } from "./types/IProfile";

export class UserService {
    public getProfile(): AxiosPromise<IProfile> {
        return api.get("/UserProfiles", {
            urlType: "api",
        })
    }
}