import { AxiosPromise } from "axios";
import { api } from "../../../api";
import { ROLES } from "../../../global";
import { store } from "../../../store";
import { logout } from "../../../store/user/actions";
import { ILoginData } from "./types/ILoginData";
import { ILoginResponse } from "./types/ILoginResponse";
//import { IRegisterData } from "./types/IRegisterData";
import { IToken } from "./types/IToken";

export class AuthService {
    private _refreshRequest: AxiosPromise<any> | null = null

    private async refresh(): Promise<string | null> {
        const refreshToken = this.getToken().refreshToken

        try {
            if (this._refreshRequest === null) {
                this._refreshRequest = api.post(`/Auth/tokens/${refreshToken}/refresh`, {}, {
                    skipTokenChecking: true,
                    urlType: "api"
                })
            }

            const response: any = await this._refreshRequest
            this._refreshRequest = null

            const roles = response.data.roles.split(",")

            if (!roles.some(role => role === ROLES.Admin) && !roles.some(role => role === ROLES.Partner) && !roles.some(role => role === ROLES.Carrier)) {
                store.dispatch(logout())

                return null
            }


            this.saveToken(response.data.accessToken, response.data.refreshToken, response.data.expires)

            return response.data.accessToken
        }
        catch (error) {
            store.dispatch(logout())

            return null
        }
    }

    public login(data: ILoginData): AxiosPromise<ILoginResponse> {
        return api.post("/Auth/login", data, {
            skipAuth: true,
            urlType: "api"
        })
    }

    // public register(data: IRegisterData): AxiosPromise<any> {
    //     return api.post("/Auth/register", data)
    // }

    public async checkToken(): Promise<string | null> {
        const token = this.getToken()

        if (token.accessToken && Date.now() > (token?.expires || 0)) {
            return await this.refresh()
        }

        return token.accessToken
    }

    public saveToken(accessToken: string, refreshToken: string, expires: number): void {
        localStorage.setItem("user", JSON.stringify({
            accessToken,
            refreshToken,
            expires: Date.now() + expires * 1000,
        }))
        console.log(Date.now() + expires * 1000)
    }

    public deleteToken(): void {
        localStorage.removeItem("user")
    }

    public getToken(): IToken {
        const token = JSON.parse(localStorage.getItem("user") || "{}")

        if (token) return {
            accessToken: token.accessToken ? token.accessToken : null,
            refreshToken: token.refreshToken ? token.refreshToken : null,
            expires: token.expires ? token.expires : 0,
        }
        else return {
            accessToken: null,
            refreshToken: null,
            expires: 0,
        }
    }
}