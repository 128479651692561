import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'
import { API_URL, URL_TYPE } from '../global'
import { services } from '../services'
import { store } from '../store'
import { logout } from '../store/user/actions'

const api = axios.create({
    timeout: 60000,
    urlType: "partner"
})

declare module "axios" {
    interface AxiosRequestConfig {
        skipTokenChecking?: boolean,
        skipAuth?: boolean,
        urlType?: URL_TYPE,
    }
}

api.interceptors.request.use(
    async (config: AxiosRequestConfig): Promise<AxiosRequestConfig> => {
        let newConfig = {...config}
        
        newConfig.baseURL = API_URL[newConfig.urlType][store.getState().app.mode]

        if (config.skipAuth) return newConfig

        let accessToken = services.auth.getToken().accessToken

        if (accessToken){
            if (!newConfig.skipTokenChecking) {
                accessToken = await services.auth.checkToken()
                
                if (!accessToken) return newConfig 
            }

            return {
                ...newConfig,
                headers: Object.assign({}, newConfig.headers, {
                    Authorization: `Bearer ${accessToken}`,
                })
            }
        }

        return newConfig
    },
    error => Promise.reject(error)
)

api.interceptors.response.use(
    (response: AxiosResponse<any>) => response,

    (error: { response: any }) => {
        if (error.response.status === 401) {
            store.dispatch(logout())
        }

        return Promise.reject(error.response)
    }
)

export { api }

