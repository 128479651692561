import { AxiosPromise } from "axios";
import { IDropdownWithId } from "../../../@types/IDropdownWithId";
import { api } from "../../../api";

export class ContractorsService {
    public getContractors(): AxiosPromise<IDropdownWithId[]> {
        let url = "/Contractors/List"

        return api.get(url)
    }
}