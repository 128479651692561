import { useEffect, useState } from "react"
import { useLocation } from "react-router-dom"
import getUrlParams from "../utils/getUrlParams"

const useUrl = () => {
    const location = useLocation()
        
	const [state, setState] = useState(getUrlParams())

	useEffect(() => {
		setState(getUrlParams())
	}, [location.search])

	return state
}

export default useUrl
