import { lazy } from "react"
import { Layout } from "../global"

export const mainRoutes = [
    {
        path: "/404",
        component: lazy(() => import('../components/pages/Page404')),
        layout: Layout.Client,
        name: "404",
    },
]

export const protectedRoutes = [
    {
        path: "/",
        component: lazy(() => import('../components/pages/HomePage')),
        layout: Layout.Partner,
        name: "home",
    },
    {
        path: "/report",
        component: lazy(() => import('../components/pages/ReportPage')),
        layout: Layout.Partner,
        name: "reportPage",
    },
]

export const guestsOnlyRoutes = [
    {
        path: "/login",
        component: lazy(() => import('../components/pages/LoginPage')),
        layout: Layout.Client,
        name: "login",
    },
]

export const allRoutes = [
    ...mainRoutes,
    ...protectedRoutes,
    ...guestsOnlyRoutes,
]