import cx from "classnames"
import { Col, Row } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import { API_URL, APP_STORE, COLORS, GOOGLE_PLAY, PROD_SITE_URL, SITE_VERSION } from "../../../../global"
import { Icon } from "../../Icon"
import { IClientFooter } from "./IClientFooter"
import appStore from "../../../../assets/img/icons/AppStore.svg"
import appStoreText from "../../../../assets/img/icons/AppStoreText.svg"
import arrowTop from "../../../../assets/img/icons/Frame.svg"
import googlePlay from "../../../../assets/img/icons/GooglePlay.svg"
import googlePlayText from "../../../../assets/img/icons/GooglePlayText.svg"
import socIn from "../../../../assets/img/icons/social/in.svg"
import socTg from "../../../../assets/img/icons/social/tg.svg"
import socVk from "../../../../assets/img/icons/social/vk.svg"
import socWa from "../../../../assets/img/icons/social/wa.svg"
import socYt from "../../../../assets/img/icons/social/yt.svg"
import { useSelector } from "react-redux"
import Button from "../../Button"

const ClientFooter = ({
    className
}: IClientFooter) => {
    const { t } = useTranslation()

    const isLoggedIn = useSelector((state: any) => state.user.isLoggedIn)

    return (
        <footer className={cx("client-footer py-5", className)}>
            <Row>
                <Col xs="auto" className="me-5">
                    <a href="tel:*701" className="d-flex flex-column">
                        <span className="client-footer__first-row-title">{t("clientFooter.callOwer")}</span>
                        <span className="client-footer__first-row-subtitle">*701</span>
                    </a>
                </Col>
                <Col xs="auto">
                    <a
                        href="mailto:info@tek-transport.ru"
                        className="d-flex flex-column"
                    >
                        <span className="client-footer__first-row-title">{t("clientFooter.payQuestion")}</span>
                        <span className="client-footer__first-row-subtitle">info@tek-transport.ru</span>
                    </a>
                </Col>
            </Row>
            <Row className="justify-content-between mt-4 mt-lg-5 mb-lg-5">
                <Col lg="auto" className="mb-2 mb-lg-0 px-0">
                    <div className="bg-bg d-flex justify-content-between reg-lk-block rounded-wrap">
                        <Button
                            href={isLoggedIn ? `${PROD_SITE_URL}/user-tickets` : `${PROD_SITE_URL}/registration`}
                            color="white"
                            className="me-2"
                            big
                        >
                            {
                                isLoggedIn
                                    ? t("clientFooter.myOrders")
                                    : t("clientFooter.reg")
                            }
                        </Button>
                        <Button
                            color="transparent"
                            href={isLoggedIn ? `${PROD_SITE_URL}/user` : `${PROD_SITE_URL}`}
                            big
                        >
                            <Icon
                                className="d-none d-xl-block me-1"
                                name="user"
                                size={20}
                                color={COLORS.dark}
                            />
                            <span>{t("clientFooter.lk")}</span>
                        </Button>
                    </div>
                </Col>
                <Col lg="auto" className="mb-2 mb-lg-0 px-0">
                    <div className="bg-bg rounded-wrap h-100 d-flex align-items-center justify-content-around px-lg-3 py-3 py-lg-0 social-links">
                        <a
                            href="https://www.instagram.com/avtobus_chelny_kazan/"
                            target="_blank"
                            rel="noreferrer">

                            <img src={socIn} alt="" />
                        </a>
                        <a
                            href="https://vk.com/tek_transport"
                            target="_blank"
                            rel="noreferrer">

                            <img src={socVk} alt="" />
                        </a>
                        <a href="/" target="_blank" rel="noreferrer">
                            <img src={socWa} alt="" />
                        </a>
                        <a href="/" target="_blank" rel="noreferrer">
                            <img src={socTg} alt="" />
                        </a>
                        <a href="/" target="_blank" rel="noreferrer">
                            <img src={socYt} alt="" />
                        </a>
                    </div>
                </Col>
                <Col lg="auto" className="mb-2 mb-lg-0 px-0">
                    <Row className="h-100">
                        <Col xs={6} className="pe-2">
                            <a
                                href={APP_STORE}
                                rel="noreferrer"
                                target="_blank"
                                className="store-link _apple rounded-wrap d-flex align-items-center"
                            >
                                <img src={appStore} className="logo me-2" alt="" />
                                <span className="d-flex flex-column">
                                    <span className="text-uppercase title mb-1">
                                        {t("clientFooter.uploadOn")}
                                    </span>
                                    <img src={appStoreText} className="store-logo" alt="" />
                                </span>
                            </a>
                        </Col>

                        <Col xs={6} className="ps-0">
                            <a
                                href={GOOGLE_PLAY}
                                rel="noreferrer"
                                target="_blank"
                                className="store-link _google rounded-wrap d-flex align-items-center"
                            >
                                <img src={googlePlay} className="logo me-2" alt="" />
                                <span className="d-flex flex-column">
                                    <span className="text-uppercase title mb-1">
                                        {t("clientFooter.uploadOn")}
                                    </span>
                                    <img src={googlePlayText} className="store-logo" alt="" />
                                </span>
                            </a>
                        </Col>
                    </Row>
                </Col>
                <Col xl="auto" className="d-none d-xl-block">
                    <a href="#top">
                        <span className="h-100 bg-bg rounded-wrap position-fixed btn-scroll-to-top d-flex align-items-center justify-content-center cursor-pointer">
                            <img src={arrowTop} alt="" />
                        </span>
                    </a>
                </Col>
            </Row>
            <Row className="footer-links">
                <Col xs={6} lg={3}>
                    <div>
                        <div className="title pb-3">{t("clientFooter.forPassangers.title")}</div>
                        <div className="pb-2">
                            <a href={`${PROD_SITE_URL}/payment`}>
                                {t("clientFooter.forPassangers.payment")}
                            </a>
                        </div>
                        <div className="pb-2">
                            <a href={`${PROD_SITE_URL}/call-center`}>
                                {t("clientFooter.forPassangers.callCenter")}
                            </a>
                        </div>
                        <div className="pb-2">
                            <a href={`${PROD_SITE_URL}/bus-stations`}>
                                {t("clientFooter.forPassangers.stations")}
                            </a>
                        </div>
                        <div className="pb-2">
                            <a href={`${PROD_SITE_URL}/faq`}>
                                {t("clientFooter.forPassangers.questionAnswer")}
                            </a>
                        </div>
                        <div className="pb-2">
                            <a href={`${PROD_SITE_URL}/exchange`}>
                                {t("clientFooter.forPassangers.refund")}
                            </a>
                        </div>
                    </div>
                </Col>
                <Col xs={6} lg={4}>
                    <div>
                        <div className="title pb-3">{t("clientFooter.forPartners.title")}</div>
                        {/* <div className="pb-2">
                            <a href={`${PROD_SITE_URL}/ferryman-about?advantages=advantages`}>
                                {t("clientFooter.forPartners.partnerProgram")}
                            </a>
                        </div> */}
                        <div className="pb-2">
                            <a href={`${PROD_SITE_URL}/ferryman-about?partnersReg=partnersReg`}>
                                {t("clientFooter.forPartners.registration")}
                            </a>
                        </div>
                        {/* <div className="pb-2">
                            <a href={`${PROD_SITE_URL}`}>
                                {t("clientFooter.forPartners.supporting")}
                            </a>
                        </div> */}
                        <div className="pb-2">
                            <a href={`${PROD_SITE_URL}/ferryman-about?carrier=carrier`}>
                                {t("clientFooter.forPartners.driversAndStations")}
                            </a>
                        </div>
                        <div className="pb-2">
                            <a href={`${PROD_SITE_URL}/mobile-app`}>
                                {t("clientFooter.forPartners.mobileApp")}
                            </a>
                        </div>
                    </div>
                </Col>
                <Col xs={6} lg={3}>
                    <div>
                        <div className="title pb-3">{t("clientFooter.documents.title")}</div>
                        <div className="pb-2">
                            <a
                                href={`${API_URL.api.Stage}/SystemInfo/GetEndUserLicenseAgreement`}
                                target="_blank"
                                rel="noreferrer">
                                {t("clientFooter.documents.contractOffer")}
                            </a>
                        </div>
                        <div className="pb-2">
                            <a
                                href={`${API_URL.api.Stage}/SystemInfo/getPrivacyPolicy`}
                                target="_blank"
                                rel="noreferrer">
                                {t("clientFooter.documents.userPoliticy")}
                            </a>
                        </div>
                        <div className="pb-2">
                            <a
                                href={`${API_URL.api.Stage}/SystemInfo/GetConsentToProcessing`}
                                target="_blank"
                                rel="noreferrer">
                                {t("clientFooter.documents.consentToProcessing")}
                            </a>
                        </div>
                    </div>
                </Col>
                <Col xs={6} lg={2}>
                </Col>
            </Row>
            <Col xs={12} className="d-flex flex-column text-center">
                <div className="mt-4 mt-sm-5 thumb-text mb-2 mb-md-3">
                    © TekTransport {new Date().getFullYear()}, v{SITE_VERSION}
                </div>
            </Col>
        </footer>
    )
}

export default ClientFooter