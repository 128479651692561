import { ReactComponent as Logo } from "../../../../assets/img/logo/logo.svg"

import { IClientHeader } from "./IClientHeader"
import cx from "classnames"
import { PHONE_NUMBER, PHONE_NUMBER_LINK, PROD_SITE_URL } from "../../../../global"
import Button from "../../Button"
import { Icon } from "../../Icon"

const ClientHeader = ({
    className
}: IClientHeader) => {
    return (
        <header className={cx("client-header d-flex justify-content-between py-2 py-md-4", className)}>
            <div className="d-flex align-items-center">
                <a href={PROD_SITE_URL} className="d-flex align-items-center">
                    <Logo className="client-header__logo" />
                    <div className="ms-3">
                        <div className="client-header__logo-title text-nowrap">Tek Transport</div>
                        {/* <div className="client-header__logo-subtitle">Транспортная <span className="text-nowrap d-block d-md-inline">Европейская Компания</span></div> */}
                    </div>
                </a>

                <a href={`${PROD_SITE_URL}/ferryman-about`} className="client-header__nav-elem ms-3 ms-xl-5 d-none d-xl-block">Перевозчикам</a>
                <a href={`${PROD_SITE_URL}/faq`} className="client-header__nav-elem ms-4 d-none d-xl-block">Пассажирам</a>
                <a href={`${PROD_SITE_URL}/call-center`} className="client-header__nav-elem ms-4 d-none d-xl-block">Колл-центр</a>
            </div>
            
            <div className="d-flex align-items-center">
                <a href={`tel:${PHONE_NUMBER_LINK}`} className="client-header__phone d-flex align-items-center">
                    <Icon name="phone" size={24} />
                    <span className="ms-3 d-none d-lg-inline text-nowrap">{PHONE_NUMBER}</span>
                </a>
                <Button href={`${PROD_SITE_URL}/registration`} className="ms-3 d-none d-lg-flex">Регистрация</Button>
            </div>
        </header>
    )
}

export default ClientHeader