import { SELECT_ROLE, SET_CONTRACTOR_ID, SET_CONTRACTOR_ROLES, UPDATE_PROFILE, UPDATE_ROLE } from "./constants"
import { SET_IS_LOGGED_IN } from "./constants"
import { services } from "../../services"
import { AxiosError } from "axios"
import { IErrorResponse } from "../../@types/IErrorResponse"
import { AppDispatch } from "../types"
import { ROLES } from "../../global"
import { SET_CONTRACTORS } from "../app/constants"

export const updateProfile = (): any => {
	return async (dispatch: AppDispatch) => {
		try {
			const response = await services.user.getProfile()
			dispatch({
				type: UPDATE_PROFILE,
				payload: response.data,
			})
		}
		catch (e: any) {
			if (e.isAxiosError) {
				const error: AxiosError<IErrorResponse> = e

				console.log(error.response)
			}
		}
	}
}

export const updateUserLoginStatus = (status: boolean): any => {
	return (dispatch: AppDispatch) => {
		dispatch({
			type: SET_IS_LOGGED_IN,
			payload: status,
		})
	}
}

export const login = (userName: string, password: string): any => {
	return async (dispatch: AppDispatch) => {
		try {
			const response = await services.auth.login({
				userName,
				password,
			})

			const roles = response.data.roles.split(",")
			const selectedRole = roles.find(role => role === ROLES.Partner || role === ROLES.Admin) ? ROLES.Partner : roles.find(role => role === ROLES.Carrier) ? ROLES.Carrier : ""

			if (!roles.some(role => role === ROLES.Admin) && !roles.some(role => role === ROLES.Partner) && !roles.some(role => role === ROLES.Carrier))
				return {
					success: false,
					data: {
						Detail: "Вы не имеете доступа к личному кабинету партнера"
					},
					rolesError: true,
				}

			localStorage.setItem("role", JSON.stringify(roles))

			services.auth.saveToken(response.data.accessToken, response.data.refreshToken, response.data.expires)

			dispatch({
				type: SET_IS_LOGGED_IN,
				payload: true,
			})

			dispatch({
				type: UPDATE_ROLE,
				payload: roles,
			})

			if (selectedRole) {
				localStorage.setItem("selectedRole", selectedRole)

				dispatch({
					type: SELECT_ROLE,
					payload: selectedRole,
				})
			}

			// dispatch({
			// 	type: SET_IS_ADMIN,
			// 	payload: jwt_decode(response.data.accessToken).IsAdmin
			// })

			return { success: true }
		}
		catch (e: any) {
			// if (e.isAxiosError) {
			// 	const error: AxiosError<IErrorResponse> = e

			return {
				success: false,
				data: e.data,
			}
			// }
		}
	}
}

export const logout = (): any => {
	return (dispatch: AppDispatch) => {
		services.auth.deleteToken()

		dispatch({
			type: SET_CONTRACTORS,
			payload: [],
		})

		dispatch({
			type: SET_CONTRACTOR_ID,
			payload: null,
		})

		dispatch({
			type: SET_CONTRACTOR_ROLES,
			payload: [],
		})
		dispatch({
			type: SELECT_ROLE,
			payload: null,
		})

		dispatch({
			type: SET_IS_LOGGED_IN,
			payload: false,
		})
		// dispatch({
		// 	type: SET_IS_ADMIN,
		// 	payload: false,
		// })
		dispatch({
			type: UPDATE_PROFILE,
			payload: null,
		})

		localStorage.removeItem("selectedRole")
		localStorage.removeItem("role")
		localStorage.removeItem("contractorRoles")
		localStorage.removeItem("contractorId")
	}
}

export const changeRole = (role: ROLES): any => {
	return (dispatch: AppDispatch) => {
		localStorage.setItem("selectedRole", role)

		dispatch({
			type: SELECT_ROLE,
			payload: role,
		})
	}
}

export const setContractorRoles = (roles: ROLES[]): any => {
	return (dispatch: AppDispatch) => {
		if (roles) {
			localStorage.setItem("contractorRoles", JSON.stringify(roles))

			dispatch({
				type: SET_CONTRACTOR_ROLES,
				payload: roles,
			})
		}
	}
}

export const setContractorId = (id: number): any => {
	return (dispatch: AppDispatch) => {
		localStorage.setItem("contractorId", `${id}`)

		dispatch({
			type: SET_CONTRACTOR_ID,
			payload: id,
		})
	}
} 
