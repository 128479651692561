/* eslint-disable indent */
import { AnyAction } from "redux"
import { ROLES } from "../../global"
import { SELECT_ROLE, SET_CONTRACTOR_ID, SET_CONTRACTOR_ROLES, UPDATE_PROFILE, UPDATE_ROLE } from "./constants"
import { SET_IS_LOGGED_IN } from "./constants"
import { IUserState } from "./types/IUserState"

const accessToken = JSON.parse(localStorage.getItem("user") || "{}")?.accessToken

const isValidJson = (elem: any) => {
	try {
		JSON.parse(elem)
		return true
	}
	catch {
		return false
	}
}

const initialState: IUserState = {
	profile: null,
	isLoggedIn: accessToken !== undefined,
	roles: localStorage.getItem("role")
		? isValidJson(localStorage.getItem("role"))
			? JSON.parse(localStorage.getItem("role"))
			: localStorage.getItem("role")
		: [],
	selectedRole: localStorage.getItem("selectedRole")
		? localStorage.getItem("role")
			? JSON.parse(localStorage.getItem("role")).some((role: string) => role === localStorage.getItem("selectedRole") || role === ROLES.Admin)
				? localStorage.getItem("selectedRole") as ROLES
				: null
			: null
		: null,
	contractorId: +localStorage.getItem("contractorId") ?? null,
	contractorRoles: localStorage.getItem("contractorRoles")
		? JSON.parse(localStorage.getItem("contractorRoles"))
		: []
}

export const userReducer = (state: IUserState = initialState, action: AnyAction) => {
	const { type, payload } = action

	switch (type) {
		case UPDATE_PROFILE: {
			return { ...state, profile: payload }
		}

		case SET_IS_LOGGED_IN: {
			return { ...state, isLoggedIn: payload }
		}

		case UPDATE_ROLE: {
			return { ...state, roles: payload }
		}

		case SELECT_ROLE: {
			return { ...state, selectedRole: payload }
		}

		case SET_CONTRACTOR_ID: {
			return { ...state, contractorId: payload }
		}

		case SET_CONTRACTOR_ROLES: {
			return { ...state, contractorRoles: payload }
		}

		default:
			return state
	}
}
