import { AxiosPromise } from "axios";
import { api } from "../../../api";
import { IGetPassCountResponse } from "./types/IGetPassCountResponse";
import { IFilter } from "./types/IFilter";
import { IGetLoyaltyPointsResponse } from "./types/IGetLoyaltyPointsResponse";
import { IGetCancelOrdersAndPackagesResponse } from "./types/IGetCancelOrdersAndPackagesResponse";
import { IGetPaymentsInfoResponse } from "./types/IIGetPaymentsInfoResponse";
import { IGetPackageInfoResponse } from "./types/IGetPackageInfoResponse";
import { IGetTripsInfoResponse } from "./types/IGetTripsInfoResponse";
import { IGetLocalityPassengersCountResponse } from "./types/IGetLocalityPassengersCountResponse";
import { IGetPaymentResponse } from "./types/IGetPaymentReponse";
import { IGetCommissions } from "./types/IGetCommissions";
import { IGetPaymentByRouteStop } from "./types/IGetPaymentByRouteStop";

export class ReportService {
    public getPassCount(dateFrom: string, dateTo: string, filters?: IFilter): AxiosPromise<IGetPassCountResponse> {
        let url = `/TripReports/GetReportPassCount?DateFrom=${dateFrom}&DateTo=${dateTo}`

        filters?.busIds?.forEach(busId =>
            url += `&BusIds=${busId}`
        )

        filters?.companyIds?.forEach(companyId =>
            url += `&CompanyIds=${companyId}`
        )

        filters?.contractorIds?.forEach(contractorId =>
            url += `&ContractorIds=${contractorId}`
        )

        filters?.busContractorIds?.forEach(contractorId =>
            url += `&BusContractorIds=${contractorId}`
        )

        filters?.routeIds?.forEach(routeId =>
            url += `&RouteIds=${routeId}`
        )

        if (filters?.reportType)
            url += `&ReportType=${filters?.reportType}`

        return api.get(url)
    }

    public getLoyaltyPoints(dateFrom: string, dateTo: string, filters?: IFilter): AxiosPromise<IGetLoyaltyPointsResponse> {
        let url = `/TripReports/GetReportLoyaltyPoints?DateFrom=${dateFrom}&DateTo=${dateTo}`

        filters?.busIds?.forEach(busId =>
            url += `&BusIds=${busId}`
        )

        filters?.companyIds?.forEach(companyId =>
            url += `&CompanyIds=${companyId}`
        )

        filters?.contractorIds?.forEach(contractorId =>
            url += `&ContractorIds=${contractorId}`
        )

        filters?.busContractorIds?.forEach(contractorId =>
            url += `&BusContractorIds=${contractorId}`
        )

        filters?.routeIds?.forEach(routeId =>
            url += `&RouteIds=${routeId}`
        )

        if (filters?.reportType)
            url += `&ReportType=${filters?.reportType}`

        return api.get(url)
    }

    public getCancelOrdersAndPackages(dateFrom: string, dateTo: string, filters?: IFilter): AxiosPromise<IGetCancelOrdersAndPackagesResponse> {
        let url = `/TripReports/GetReportCancelOrdersAndPackages?DateFrom=${dateFrom}&DateTo=${dateTo}`

        filters?.busIds?.forEach(busId =>
            url += `&BusIds=${busId}`
        )

        filters?.companyIds?.forEach(companyId =>
            url += `&CompanyIds=${companyId}`
        )

        filters?.contractorIds?.forEach(contractorId =>
            url += `&ContractorIds=${contractorId}`
        )

        filters?.busContractorIds?.forEach(contractorId =>
            url += `&BusContractorIds=${contractorId}`
        )

        filters?.routeIds?.forEach(routeId =>
            url += `&RouteIds=${routeId}`
        )

        if (filters?.reportType)
            url += `&ReportType=${filters?.reportType}`

        return api.get(url)
    }

    public getPaymentsInfo(dateFrom: string, dateTo: string, filters?: IFilter): AxiosPromise<IGetPaymentsInfoResponse> {
        let url = `/TripReports/GetReportPaymentsInfo?DateFrom=${dateFrom}&DateTo=${dateTo}`

        filters?.busIds?.forEach(busId =>
            url += `&BusIds=${busId}`
        )

        filters?.companyIds?.forEach(companyId =>
            url += `&CompanyIds=${companyId}`
        )

        filters?.contractorIds?.forEach(contractorId =>
            url += `&ContractorIds=${contractorId}`
        )

        filters?.busContractorIds?.forEach(contractorId =>
            url += `&BusContractorIds=${contractorId}`
        )

        filters?.routeIds?.forEach(routeId =>
            url += `&RouteIds=${routeId}`
        )

        if (filters?.reportType)
            url += `&ReportType=${filters?.reportType}`

        return api.get(url)
    }

    public getPackageInfo(dateFrom: string, dateTo: string, filters?: IFilter): AxiosPromise<IGetPackageInfoResponse> {
        let url = `/TripReports/GetReportPackageInfo?DateFrom=${dateFrom}&DateTo=${dateTo}`

        filters?.busIds?.forEach(busId =>
            url += `&BusIds=${busId}`
        )

        filters?.companyIds?.forEach(companyId =>
            url += `&CompanyIds=${companyId}`
        )

        filters?.contractorIds?.forEach(contractorId =>
            url += `&ContractorIds=${contractorId}`
        )

        filters?.busContractorIds?.forEach(contractorId =>
            url += `&BusContractorIds=${contractorId}`
        )

        filters?.routeIds?.forEach(routeId =>
            url += `&RouteIds=${routeId}`
        )

        if (filters?.reportType)
            url += `&ReportType=${filters?.reportType}`

        return api.get(url)
    }

    public getTripsInfo(dateFrom: string, dateTo: string, filters?: IFilter): AxiosPromise<IGetTripsInfoResponse> {
        let url = `/TripReports/GetReportTripsInfo?DateFrom=${dateFrom}&DateTo=${dateTo}`

        filters?.busIds?.forEach(busId =>
            url += `&BusIds=${busId}`
        )

        filters?.companyIds?.forEach(companyId =>
            url += `&CompanyIds=${companyId}`
        )

        filters?.contractorIds?.forEach(contractorId =>
            url += `&ContractorIds=${contractorId}`
        )

        filters?.busContractorIds?.forEach(contractorId =>
            url += `&BusContractorIds=${contractorId}`
        )

        filters?.routeIds?.forEach(routeId =>
            url += `&RouteIds=${routeId}`
        )

        if (filters?.reportType)
            url += `&ReportType=${filters?.reportType}`

        return api.get(url)
    }

    public getLocalityPassengersCount(dateFrom: string, dateTo: string, filters?: IFilter): AxiosPromise<IGetLocalityPassengersCountResponse> {
        let url = `/TripReports/GetReportLocalityPassengersCount?DateFrom=${dateFrom}&DateTo=${dateTo}`

        filters?.busIds?.forEach(busId =>
            url += `&BusIds=${busId}`
        )

        filters?.companyIds?.forEach(companyId =>
            url += `&CompanyIds=${companyId}`
        )

        filters?.contractorIds?.forEach(contractorId =>
            url += `&ContractorIds=${contractorId}`
        )

        filters?.busContractorIds?.forEach(contractorId =>
            url += `&BusContractorIds=${contractorId}`
        )

        filters?.routeIds?.forEach(routeId =>
            url += `&RouteIds=${routeId}`
        )

        if (filters?.reportType)
            url += `&ReportType=${filters?.reportType}`

        return api.get(url)
    }

    public getPayment(dateFrom: string, dateTo: string, filters?: IFilter): AxiosPromise<IGetPaymentResponse> {
        let url = `/TripReports/GetPaymentReport?DateFrom=${dateFrom}&DateTo=${dateTo}`

        filters?.busIds?.forEach(busId =>
            url += `&BusIds=${busId}`
        )

        filters?.companyIds?.forEach(companyId =>
            url += `&CompanyIds=${companyId}`
        )

        filters?.contractorIds?.forEach(contractorId =>
            url += `&ContractorIds=${contractorId}`
        )

        filters?.busContractorIds?.forEach(contractorId =>
            url += `&BusContractorIds=${contractorId}`
        )

        filters?.routeIds?.forEach(routeId =>
            url += `&RouteIds=${routeId}`
        )

        if (filters?.reportType)
            url += `&ReportType=${filters?.reportType}`

        return api.get(url)
    }

    public getCommissions(dateFrom: string, dateTo: string, filters?: IFilter): AxiosPromise<IGetCommissions[]> {
        let url = `/TripReports/GetCommissions?DateFrom=${dateFrom}&DateTo=${dateTo}`

        filters?.busIds?.forEach(busId =>
            url += `&BusIds=${busId}`
        )

        filters?.companyIds?.forEach(companyId =>
            url += `&CompanyIds=${companyId}`
        )

        filters?.contractorIds?.forEach(contractorId =>
            url += `&ContractorIds=${contractorId}`
        )

        filters?.busContractorIds?.forEach(contractorId =>
            url += `&BusContractorIds=${contractorId}`
        )

        filters?.routeIds?.forEach(routeId =>
            url += `&RouteIds=${routeId}`
        )

        if (filters?.reportType)
            url += `&ReportType=${filters?.reportType}`

        return api.get(url)
    }

    public getPaymentReportByRouteStop(dateFrom: string, dateTo: string, filters?: IFilter): AxiosPromise<IGetPaymentByRouteStop[]> {
        let url = `/TripReports/GetReportPaymentsByDates?DateFrom=${dateFrom}&DateTo=${dateTo}`

        filters?.busIds?.forEach(busId =>
            url += `&BusIds=${busId}`
        )

        filters?.companyIds?.forEach(companyId =>
            url += `&CompanyIds=${companyId}`
        )

        filters?.contractorIds?.forEach(contractorId =>
            url += `&ContractorIds=${contractorId}`
        )

        filters?.busContractorIds?.forEach(contractorId =>
            url += `&BusContractorIds=${contractorId}`
        )

        filters?.routeIds?.forEach(routeId =>
            url += `&RouteIds=${routeId}`
        )

        if (filters?.reportType)
            url += `&ReportType=${filters?.reportType}`

        return api.get(url)
    }

    public getPaymentReportByRouteStopSummary(dateFrom: string, dateTo: string, filters?: IFilter): AxiosPromise<IGetPaymentByRouteStop> {
        let url = `/TripReports/GetReportPaymentsByDatesSummary?DateFrom=${dateFrom}&DateTo=${dateTo}`

        filters?.busIds?.forEach(busId =>
            url += `&BusIds=${busId}`
        )

        filters?.companyIds?.forEach(companyId =>
            url += `&CompanyIds=${companyId}`
        )

        filters?.contractorIds?.forEach(contractorId =>
            url += `&ContractorIds=${contractorId}`
        )

        filters?.busContractorIds?.forEach(contractorId =>
            url += `&BusContractorIds=${contractorId}`
        )

        filters?.routeIds?.forEach(routeId =>
            url += `&RouteIds=${routeId}`
        )

        if (filters?.reportType)
            url += `&ReportType=${filters?.reportType}`

        return api.get(url)
    }
}