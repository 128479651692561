import { Container } from "react-bootstrap"
import ClientFooter from "../../common/client/ClientFooter"
import ClientHeader from "../../common/client/ClientHeader"

const ClientLayout = ({ children }) => {
    return (
        <div className="client-layout">
            <Container fluid="md">
                <ClientHeader />

                {children}

                <ClientFooter />
            </Container>
        </div>
    )
}

export default ClientLayout