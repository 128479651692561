import { useDispatch, useSelector } from "react-redux"
import { ROLES } from "../../../global"
import { changeRole } from "../../../store/user/actions"
import cx from "classnames"
import { IStore } from "../../../store/types"

const RoleSwitcher = () => {
    const roles = [
        {
            name: "Партнер",
            id: ROLES.Partner,
        },
        {
            name: "Перевозчик",
            id: ROLES.Carrier,
        }
    ]

    const dispatch = useDispatch()

    const selectedRole = useSelector((store: IStore) => store.user.selectedRole)

    return (
        <div className="role-switcher">
            {roles.map(role => (
                <div 
                    key={role.id} 
                    onClick={() => dispatch(changeRole(role.id))}
                    className={cx("cursor-pointer p-1 text-center switcher-button mb-1", {
                        "bg-primary text-white": role.id === selectedRole
                    })}
                >
                    {role.name}
                </div>
            ))}
        </div>
    )
}

export default RoleSwitcher