import { IFooter } from "./IFooter"
import cx from "classnames"
import { Col, Row } from "react-bootstrap"

const Footer = ({
    className
}: IFooter) => {
    return (
        <footer className={cx("footer py-2 px-lg-5", className)}>
            <Row className="justify-content-around align-items-center">
                <Col xs="auto" className="text-gray-custom">Партнерская программа</Col>
                <Col xs="auto" className="text-gray-custom">Лицензионный договор</Col>
                <Col xs="auto" className="text-gray-custom">Политика конфиденциальности</Col>
                <Col xs="auto" className="text-gray-custom">Русский</Col>
                <Col xs="auto" className="text-gray-custom">© TekTransport {new Date().getFullYear()}</Col>
            </Row>
        </footer>
    )
}

export default Footer