export interface INavElem {
    path: string | null,
    title: string,
    isCategory: boolean,
    children: INavElem[],
}


export const navList: INavElem[] = [
    {
        path: null,
        title: "Отчеты",
        isCategory: true,
        children: [
            {
                path: "/",
                title: "nav.home",
                isCategory: false,
                children: [],
            },
            {
                path: "/report",
                title: "nav.mutualSettlement",
                isCategory: false,
                children: [],
            }
        ]
    }
]