import { AxiosPromise } from "axios";
import { IDropdown } from "../../../@types/IDropdown";
import { api } from "../../../api";

export class RouteService {
    public getRoutesList(contractorId: number, role: string): AxiosPromise<IDropdown[]> {
        let url = `/Routes/List?contractorId=${contractorId}&role=${role}`

        return api.get(url)
    }
}