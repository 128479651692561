/* eslint-disable indent */
import { AnyAction } from "redux"
//import { IDropdownWithId } from "../../@types/IDropdownWithId"
import { Mode, TEST_MODE_PASS } from "../../global"
import { SET_CONTRACTORS, SET_MODE } from "./constants"
import { IAppState } from "./types/IAppState"
import { IContractor } from "./types/IContractor"

const initialState: IAppState = {
	mode: localStorage.getItem("testMode") === TEST_MODE_PASS ? Mode.Test : Mode.Stage,
	contractors: [],
}

export const appReducer = (state: IAppState = initialState, action: AnyAction) => {
	const { type, payload } = action

	switch (type) {
		case SET_MODE: {
			const _payload: Mode = payload

			return {
				...state, mode: _payload
			}
		}

		case SET_CONTRACTORS: {
			const _payload: IContractor[] = payload

			return {
				...state, contractors: _payload
			}
		}

		default:
			return state
	}
}
