import { useEffect, useState, Suspense } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Navigate, Routes, Route, useLocation } from "react-router-dom"
import { mainRoutes, protectedRoutes, allRoutes, guestsOnlyRoutes } from "./routes"
import { ReactComponent as Logo } from "./assets/img/logo/logo.svg"
//import { registerLocale } from "react-datepicker"
//import ru from "date-fns/locale/ru";
import { logout, updateProfile } from "./store/user/actions"
import { scroll } from "./utils/scroll"
import ClientLayout from "./components/layouts/ClientLayout"
import PartnerLayout from "./components/layouts/PartnerLayout"
import { Layout, Mode, TEST_MODE_PASS } from "./global"
import useUrl from "./hooks/useUrl"
import { changeAppMode, getContractors } from "./store/app/actions"
import { IStore } from "./store/types"

const App = () => {
	const location = useLocation()
	const isLoggedIn = useSelector((store: IStore) => store.user.isLoggedIn)
	//const isAdmin = useSelector(store => store.user.isAdmin)

	const dispatch = useDispatch()

	const [layout, setLayout] = useState<Layout | null>(null)

	const { testMode, pass } = useUrl()

	const mode = useSelector((state: IStore) => state.app.mode)

	// useEffect(() => {
	// 	registerLocale("ru", ru)
	// }, [])

	useEffect(() => {
		scroll("root")

		setLayout(allRoutes.find(route => route.path === location.pathname)?.layout)
	}, [location.pathname])

	useEffect(() => {
		if (isLoggedIn) {
			dispatch(updateProfile())
			dispatch(getContractors())
		}
	}, [dispatch, isLoggedIn])

	useEffect(() => {
		if (testMode === "true" && pass === TEST_MODE_PASS && localStorage.getItem("testMode") !== TEST_MODE_PASS) {
			dispatch(logout())
			localStorage.setItem("testMode", pass)
			dispatch(changeAppMode(Mode.Test))
		}
		else if (testMode === "false" && localStorage.getItem("testMode")) {
			dispatch(logout())
			localStorage.removeItem("testMode")
			dispatch(changeAppMode(Mode.Stage))
		}
	}, [testMode, pass, dispatch])

	const loadingComponent = (
		<div className="h-100 d-flex flex-column justify-content-center align-items-center p-5">
			<Logo className="mw-100" />
		</div>
	)

	const renderMainRoute = route => {
		const Page = route.component

		if (route.path) {
			return <Route key={route.name} path={route.path} element={<Page />} />
		}
	}

	const renderProtectedRoute = route => {
		const Page = route.component

		if (route.path) {
			return <Route key={route.name} path={route.path} element={isLoggedIn ? <Page /> : <Navigate to="/login" />} />
		}
	}

	const renderGuestsRoute = route => {
		const Page = route.component

		if (route.path) {
			return <Route key={route.name} path={route.path} element={isLoggedIn ? <Navigate to="/" /> : <Page />} />
		}
	}

	if (layout === Layout.Client) {
		return (
			<>
				{mode === Mode.Test && <div className="bg-danger text-white text-center py-2">TEST MODE</div>}

				<ClientLayout>
					<Suspense fallback={loadingComponent}>
						<Routes>
							{mainRoutes.map(renderMainRoute)}
							{protectedRoutes.map(renderProtectedRoute)}
							{guestsOnlyRoutes.map(renderGuestsRoute)}

							<Route path="*" element={<Navigate to="/404" />} />
						</Routes>
					</Suspense>
				</ClientLayout>

				{mode === Mode.Test && <div className="bg-danger text-white text-center py-2">TEST MODE</div>}
			</>
		)
	}
	if (layout === Layout.Partner) {
		return (
			<>
				{mode === Mode.Test && <div className="bg-danger text-white text-center py-2">TEST MODE</div>}

				<PartnerLayout>
					<Suspense fallback={loadingComponent}>
						<Routes>
							{mainRoutes.map(renderMainRoute)}
							{protectedRoutes.map(renderProtectedRoute)}
							{guestsOnlyRoutes.map(renderGuestsRoute)}

							<Route path="*" element={<Navigate to="/404" />} />
						</Routes>
					</Suspense>
				</PartnerLayout>

				{mode === Mode.Test && <div className="bg-danger text-white text-center py-2">TEST MODE</div>}
			</>
		)
	}
	else {
		return (
			<>
				{mode === Mode.Test && <div className="bg-danger text-white text-center py-2">TEST MODE</div>}

				<Suspense fallback={loadingComponent}>
					<Routes>
						{mainRoutes.map(renderMainRoute)}
						{protectedRoutes.map(renderProtectedRoute)}
						{guestsOnlyRoutes.map(renderGuestsRoute)}

						<Route path="*" element={<Navigate to="/404" />} />
					</Routes>
				</Suspense>

				{mode === Mode.Test && <div className="bg-danger text-white text-center py-2">TEST MODE</div>}
			</>
		)
	}
}

export default App
