import { ReactComponent as Squares } from "../../../assets/img/icons/Squares.svg"
import { ReactComponent as ArrowRight } from "../../../assets/img/icons/ArrowRight.svg"

import { NavLink } from "react-router-dom"
import { INavElem, navList } from "./navList"
import { INav } from "./INav"
import cx from "classnames"
import { useTranslation } from "react-i18next"
import useUrl from "../../../hooks/useUrl"
import { useCallback, useEffect, useState } from "react"
import RoleSwitcher from "../RoleSwitcher"
import { useDispatch, useSelector } from "react-redux"
import { IStore } from "../../../store/types"
import { ROLES } from "../../../global"

const Nav = ({
    className,
    handleClick,
}: INav) => {
    const { t } = useTranslation()

    const dispatch = useDispatch()

    const roles = useSelector((store: IStore) => store.user.contractorRoles)

    const { start, end } = useUrl()

    const [isShowSwitcher, setIsShowSwitcher] = useState(false)

    useEffect(() => {
        if (roles) {
            if (roles.some(role => role === ROLES.Admin) || (roles.some(role => role === ROLES.Partner) && roles.some(role => role === ROLES.Carrier))) {
                setIsShowSwitcher(true)
            }
            else {
                setIsShowSwitcher(false)
            }
        }
    }, [dispatch, roles])

    const renderNavElem = useCallback((elem: INavElem, isPoint: boolean = false) => {
        if (elem.isCategory) return (
            <div className="py-1" key={elem.title}>
                <div className="d-flex align-items-center">
                    <div className="nav__category-icon d-flex justify-content-center align-items-center">
                        <Squares />
                    </div>
                    <div className="ms-2">{t(elem.title)}</div>
                </div>
                <div className="nav__children mt-2">
                    {elem.children.map(child => renderNavElem(child))}
                </div>
            </div>
        )
        else return (
            <div className="py-1" key={elem.title}>
                {elem.path ? (
                    <NavLink
                        to={elem.path + (start && end ? `?start=${start}&end=${end}` : "")}
                        className={({ isActive }) => isActive ? "nav__link nav__link--active" : "nav__link"}
                        onClick={handleClick}
                    >
                        <div>
                            {isPoint ? <div className="nav__circle"></div> : <ArrowRight />}
                        </div>
                        <div className="ms-2 mt-1">{t(elem.title)}</div>
                    </NavLink>
                ) : (
                    <>
                        <div className="nav__link">
                            <div>
                                <ArrowRight />
                            </div>
                            <div className="ms-2">{t(elem.title)}</div>
                        </div>
                        <div className="ms-3 mt-2">
                            {elem.children.map(child => renderNavElem(child, true))}
                        </div>
                    </>
                )}

            </div>
        )
    }, [end, handleClick, start, t])

    return (
        <nav className={cx("nav py-3 px-2", className)}>
            {isShowSwitcher &&
                <div className="mb-3">
                    <RoleSwitcher />
                </div>}

            {navList.map(elem => renderNavElem(elem))}
        </nav>
    )
}

export default Nav