import { useEffect } from "react"

export const useClickOutside = (ref, callback, dependecy) => {
	useEffect(() => {
		const handleClick = e => {
			if (ref.current && !ref.current.contains(e.target)) {
				callback()
			}
		}

		if (dependecy) {
			document.addEventListener("mouseup", handleClick)
		}
		else {
			document.removeEventListener("mouseup", handleClick)
		}
	
		return () => {
			document.removeEventListener("mouseup", handleClick)
		}
	}, [dependecy, ref, callback])
}
