import { useEffect, useState, useMemo, useRef } from "react"
import ReactSelect from 'react-select'
import makeAnimated from 'react-select/animated'
import { useController } from "react-hook-form"
import cx from "classnames"

const Select = ({
    label = "",
    options = [],
    className = "",
    name,
    isLoading = false,
    control,
    error = "",
    errors = {},
    isMulti = false,
    string = false,
    required = false,
    disabled = false,
    width = "",
    noClear = false,
    onMenuOpen = () => { },
    primary = false,
    ...props
}) => {
    const { field, fieldState } = useController({
        name,
        control,
        rules: {
            required,
        }
    })
    const select = useRef(null)
    const VALUE_FOR_ALL = "VALUE_FOR_ALL"
    const selectAllOptions = useMemo(() => ({
        value: VALUE_FOR_ALL,
        label: "Выбрать всё",
    }), [])
    const [optionsList, setOptionsList] = useState([selectAllOptions, ...options])

    useEffect(() => {
        setOptionsList([selectAllOptions, ...options])
    }, [options, selectAllOptions])

    const animatedComponents = makeAnimated()

    const customStyles = {
        menu: provided => ({
            ...provided,
            marginTop: "5px",
            borderRadius: "10px",
        }),
        // option: provided => ({
        //     ...provided,
        //     borderRadius: "10px",
        // }),
    }

    const changeSelectValue = e => {
        if (isMulti) {
            if (e.length > 0 && e[e.length - 1].value === VALUE_FOR_ALL) {
                field.onChange(options.map(obj => obj.value))
                select.current?.blur()

            }
            else {
                field.onChange(e?.map(obj => obj.value))
            }
        }
        else {
            field.onChange(
                e?.value === null || e?.value === undefined || e?.value === ""
                    ? null
                    : string
                        ? e.value
                        : +e.value
            )
        }
    }

    return (
        <div
            className={cx("text-start", className)}
            style={{
                width: width ? width : "100%",
            }}
        >
            <label
                className={cx(
                    "select w-100 py-1",
                    {
                        "select--primary": primary,
                        "select--error": error,
                    }
                )}
                {...props}
            >
                {label && <div className="select__label px-3">{label}</div>}

                {/* <Controller
                    name={name}
                    control={control}
                    rules={{ required: required}} 
                    render={({ field }) => ( */}

                <ReactSelect
                    closeMenuOnSelect={!isMulti}
                    components={animatedComponents}
                    options={isMulti ? optionsList : options}
                    isMulti={isMulti}
                    ref={select}
                    placeholder="Выбрать"
                    isLoading={isLoading}
                    isClearable={!noClear}
                    backspaceRemovesValue
                    isSearchable={false}
                    classNamePrefix="react-select-main"
                    isDisabled={disabled}
                    {...(disabled && { placeholder: "" })}
                    onChange={changeSelectValue}
                    value={
                        isMulti
                            ? field.value
                                ? field.value.map(val => options.find(option => option.value === val))
                                : []
                            : field.value
                                ? options.find(option => option.value === field.value)
                                : null
                    }
                    styles={customStyles}
                    onMenuOpen={onMenuOpen}
                />
                {/* )}
                /> */}
            </label>

            {errors?.[name]?.type === "required" && <span className="text-danger">Заполните это поле</span>}

            {error && <span className="text-danger">{error}</span>}
        </div>
    )
}

export default Select