import { useDispatch } from "react-redux"
import { applyMiddleware, combineReducers, createStore } from "redux"
import thunk from "redux-thunk"
import { appReducer } from "./app/reducer"
import { AppDispatch } from "./types"
import { userReducer } from "./user/reducer"

export const store = createStore(
	combineReducers({
		app: appReducer,
		user: userReducer,
	}),
	applyMiddleware(thunk)
)

export const useAppDispatch = () => useDispatch<AppDispatch>()