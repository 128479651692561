import cx from "classnames"
import { useRef } from "react"
import { useClickOutside } from "../../../hooks/useClickOutside"
import { IDropdown } from "./IDropdown"

const Dropdown = ({
    className,
    right = false,
    isOpened,
    close,
    children,
    fast = false,
    noAnimation = false,
}: IDropdown) => {
    const dropdown = useRef(null)

    useClickOutside(dropdown, () => close(), isOpened)

    return (
        <div
            className={cx("dropdown", className, {
                "dropdown--right": right,
                "dropdown--closed": !isOpened,
                "dropdown--fast": fast,
                "dropdown--no-animation": noAnimation,
            })}
            ref={dropdown}
        >
            <div className="p-3">
                {children}
            </div>
        </div>
    )
}

export default Dropdown