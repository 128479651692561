import i18n from "i18next"
import LanguageDetector from "i18next-browser-languagedetector"
import { initReactI18next } from "react-i18next"
import backend from "i18next-xhr-backend"
//import en from "./locales/en.json"
import ru from "./locales/ru.json"

i18n
	.use(LanguageDetector)
	.use(backend)
	.use(initReactI18next)
	.init({
		resources: {
			//en: { translation: en },
			ru: { translation: ru },
		},
		fallbackLng: "ru",
		supportedLngs: ["ru"],
		react: {
			useSuspense: false,
		},
		interpolation: {
			escapeValue: false,
		},
	})

export default i18n
