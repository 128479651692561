export enum Layout {
    Client = "Client",
    Partner = "Partner",
}

// =========== SITE VERSION ==========

export const SITE_VERSION = "2.13.1 (от 31.7.2024)"

// ============ API ================
export enum Mode {
    Stage = "Stage",
    Test = "Test",
}

// ============ REPORT TYPES ================
export enum ReportTypes {
    General,
    ByBuses,
}

export type URL_TYPE = "api" | "partner"

type API_URL_TYPE = {
    [key in URL_TYPE]: {
        [key in Mode]: string
    }
}

export const API_URL: API_URL_TYPE = {
    "api": {
        [Mode.Stage]: process.env.REACT_APP_API_URL,
        [Mode.Test]: process.env.REACT_APP_TESTAPI_URL
    },
    "partner": {
        [Mode.Stage]: process.env.REACT_APP_PARTNERAPI_URL,
        [Mode.Test]: process.env.REACT_APP_TESTPARTNERAPI_URL
    }
}

export const TEST_MODE_PASS = "testMode"

// ============ Links ================

export const PROD_SITE_URL = "https://www.tek-transport.ru"

// ============ Colors ===============

export enum COLORS {
    dark = "#2E3134",
    green = "#64AE5F",
    textFade = "#9199A1",
    bg = "#F2F2F2",
    white = "white",
}

// =================== MOBILE APP LINKS =========================

export const APP_STORE = "https://apps.apple.com/ru/app/tektransport/id1568045718#?platform=iphone"
export const GOOGLE_PLAY = "https://play.google.com/store/apps/details?id=com.cher.biletion_office_passenger"

export const APP_STORE_DRIVER = "https://apps.apple.com/ru/app/tek-driver/id1568303591#?platform=iphone"
export const GOOGLE_PLAY_DRIVER = "https://play.google.com/store/apps/details?id=com.cher.biletion_office"

// =================== PHONE NUMBERS =========================

export const PHONE_NUMBER = "*701"
export const PHONE_NUMBER_LINK = "*701"

// =================== LANGUAGES =========================

export const LANGUAGES = [
    { value: "ru", label: "Русский (RU)" },
    //{ value: "en", label: "English (EN)" },
]

// export const MOUNTH = [
// 	"m1",
// 	"m2",
// 	"m3",
// 	"m4",
// 	"m5",
// 	"m6",
// 	"m7",
// 	"m8",
// 	"m9",
// 	"m10",
// 	"m11",
// 	"m12",
// ]

// export const DAYS = ["d1", "d2", "d3", "d4", "d5", "d6", "d7"]

// =================== ROLES =========================

export enum ROLES {
    Dispatcher = "DISPATCHER",
    Driver = "DRIVER",
    ApiUser = "API_USER",
    Chief = "CHIEF",
    Mourner = "MOURNER",
    ShiftSupervisor = "SHIFTSUPERVISOR",
    Service = "SERVICE",
    Partner = "PARTNER",
    Carrier = "CARRIER",
    Accountant = "ACCOUNTANT",
    Admin = "ADMIN",
    User = "USER",
}