import { Container } from "react-bootstrap"
import Footer from "../../common/Footer"
import Header from "../../common/Header"
import Nav from "../../common/Nav"

const PartnerLayout = ({ children }) => {
    return (
        <div className="partner-layout">
            <Header className="partner-layout__header" />

            <Container fluid="md" className="position-relative">
                <div className="partner-layout__container">
                    <div className="partner-layout__grid h-100">
                        <div className="d-none d-md-block">
                            <Nav className="partner-layout__nav" />
                        </div>

                        <div className="partner-layout__page">
                            {children}
                        </div>
                    </div>
                </div>

                <Footer className="mt-2" />
            </Container>
        </div>
    )
}

export default PartnerLayout