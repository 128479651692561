import { IServices } from "./IServices";
import { AuthService } from "./list/auth/AuthService";
import { BusesService } from "./list/buses/BusesService";
import { ContractorsService } from "./list/contractors/ContractorsService";
import { ReportService } from "./list/reports/ReportService";
import { RouteService } from "./list/routes/RoutesService";
import { UserService } from "./list/user/UserService";

export const services: IServices = {
    auth: new AuthService(),
    user: new UserService(),
    reports: new ReportService(),
    routes: new RouteService(),
    buses: new BusesService(),
    contractors: new ContractorsService(),
}