import { Link } from "react-router-dom"
import cx from "classnames"
import { IButton } from "./IButton"

const Button = ({
    link,
    href,
    className,
    children,
    color = "primary",
    onClick,
    big = false,
    partner = false,
    type = "button",
}: IButton) => {
    if (link) return (
        <Link
            to={link}
            className={cx(
                "button px-3 d-flex justify-content-center align-items-center",
                `button--${color}`,
                className,
                {
                    "p-4 button--big": big,
                    "px-4 py-1 button--partner": partner,
                }
            )}
        >
            {children}
        </Link>
    )
    else if (href) return (
        <a
            href={href}
            className={cx(
                "button px-3 d-flex justify-content-center align-items-center",
                `button--${color}`,
                className,
                {
                    "p-4 button--big": big,
                    "px-4 py-1 button--partner": partner,
                }
            )}
        >
            {children}
        </a>
    )
    else return (
        <button
            className={cx(
                "button px-3 d-flex justify-content-center align-items-center",
                `button--${color}`,
                className,
                {
                    "p-4 button--big": big,
                    "px-4 py-1 button--partner": partner,
                }
            )}
            onClick={onClick}
            type={type}
        >
            {children}
        </button>
    )
}

export default Button