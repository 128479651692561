import { ReactComponent as Logo } from "../../../assets/img/logo/logo.svg"
import { ReactComponent as Burger } from "../../../assets/img/icons/Burger.svg"

import { IHeader } from "./IHeader"
import cx from "classnames"
import { Container, Offcanvas } from "react-bootstrap"
import { COLORS, PROD_SITE_URL } from "../../../global"
//import LangSwitcher from "../LangSwitcher"
import { useTranslation } from "react-i18next"
import { useEffect, useRef, useState } from "react"
import Nav from "../Nav"
import Select from "../Select"
import { useForm } from "react-hook-form"
import { useClickOutside } from "../../../hooks/useClickOutside"
import Dropdown from "../Dropdown"
import { useDispatch, useSelector } from "react-redux"
import { changeRole, logout, setContractorId, setContractorRoles } from "../../../store/user/actions"
import { Icon } from "../Icon"
import { IStore } from "../../../store/types"

const Header = ({
    className
}: IHeader) => {
    const { t } = useTranslation()
    const dispatch = useDispatch()

    // const currencyList = [
    //     { value: 1, label: "RUB" }
    // ]

    // const companyList = [
    //     { value: 1, label: "AB Столичный" }
    // ]

    const contractorId = useSelector((store: IStore) => store.user.contractorId)

    const contractorsStore = useSelector((store: IStore) => store.app.contractors)

    const { control, watch, setValue } = useForm({
        defaultValues: {
            currency: 1,
            company: 1,
            contractor: contractorId,
        }
    })

    // useEffect(() => {
    //   console.log(contractorId)
    // }, [contractorId])


    const watchContractorId = watch("contractor")

    const contractors = contractorsStore.map(contractor => ({
        value: contractor.id,
        label: contractor.name,
    }))

    const [isNavOpened, setIsNavOpened] = useState(false)
    const [isProfileOpened, setIsProfileOpened] = useState(false)

    const profile = useSelector((store: IStore) => store.user.profile)

    const selectedRole = useSelector((store: IStore) => store.user.selectedRole)

    const profileRef = useRef(null)

    useClickOutside(profileRef, () => setIsProfileOpened(false), isProfileOpened)

    useEffect(() => {
        if (contractorsStore.length > 0) {
            if (+watchContractorId) {
                dispatch(setContractorRoles(contractorsStore.find(contractor => contractor.id === watchContractorId)?.roles))
                dispatch(setContractorId(watchContractorId))
                if (!contractorsStore.find(contractor => contractor.id === watchContractorId)?.roles.includes(selectedRole)) {
                    dispatch(changeRole(contractorsStore.find(contractor => contractor.id === watchContractorId)?.roles[0]))
                }
            }
            else {
                setValue('contractor', contractorsStore[0]?.id)
            }
        }
    }, [contractorsStore, dispatch, selectedRole, setValue, watchContractorId])


    return (
        <header className={cx("header", className)}>
            <Container fluid="md" className="header__content h-100">
                <div className="d-flex align-items-center justify-content-between h-100">
                    <a href={PROD_SITE_URL} className="d-flex align-items-center">
                        <Logo className="header__logo" />
                        <div className="ms-3">
                            <div className="header__logo-title text-nowrap">Tek Transport</div>
                            {/* <div className="header__logo-subtitle">{t("header.logoSubtitle1")} <span className="text-nowrap d-block d-md-inline">{t("header.logoSubtitle2")}</span></div> */}
                        </div>
                    </a>

                    <div className="d-none d-md-block flex-grow-1 h4 mb-0 ms-5">{t("header.title")} {selectedRole ? t(`header.${selectedRole}`) : profile?.shortName}</div>

                    <div className="d-none d-md-flex align-items-center">
                        {/* <LangSwitcher />
                        <Select
                            name="currency"
                            control={control}
                            options={currencyList}
                            className="ms-2"
                            width="100px"
                            noClear
                        /> */}
                        <div className="position-relative ms-2" ref={profileRef}>
                            <div className="header__profile d-flex justify-content-between align-items-center p-3" onClick={() => setIsProfileOpened(true)}>
                                <div>
                                    <Icon
                                        name="user"
                                        size={23}
                                        color={COLORS.white}
                                    />
                                </div>

                                <div className="header__profile-name">{contractorsStore?.find(contractor => contractor.id === contractorId)?.name ?? profile?.shortName}</div>

                                <div></div>
                            </div>

                            <Dropdown isOpened={isProfileOpened} close={() => setIsProfileOpened(false)} className="text-end">
                                <div className="text-start">
                                    {profile?.shortName}
                                </div>
                                <div
                                    className="my-2 mb-4"
                                >
                                    <Select
                                        name="contractor"
                                        control={control}
                                        options={contractors}
                                        primary
                                        noClear
                                    />
                                </div>
                                <div className="header__logout text-center cursor-pointer" onClick={() => dispatch(logout())}>Выйти</div>
                            </Dropdown>
                        </div>
                    </div>

                    <div className="d-md-none text-white" onClick={() => setIsNavOpened(prev => !prev)}><Burger className="header__menu" /></div>
                </div>
            </Container>

            <Offcanvas
                show={isNavOpened}
                onHide={() => setIsNavOpened(false)}
                placement="end"
            >
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Меню</Offcanvas.Title>
                </Offcanvas.Header>

                <Offcanvas.Body>
                    <Nav handleClick={() => setIsNavOpened(false)} />
                </Offcanvas.Body>
            </Offcanvas>
        </header>
    )
}

export default Header