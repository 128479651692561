import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import { Provider } from "react-redux"
import { BrowserRouter } from "react-router-dom"
import "./i18next"
import { store } from "./store"
import './assets/css/index.scss'
import "moment/locale/ru" 
import Moment from "react-moment"
import moment from "moment"

moment.locale("ru")
Moment.globalLocale = "ru"

ReactDOM.render(
  <React.StrictMode>
		<Provider store={store}>
			<BrowserRouter>
				<App />
			</BrowserRouter>
		</Provider>
	</React.StrictMode>,
  document.getElementById('root')
)
